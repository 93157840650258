import React from 'react';

import Layout from '../components/Layout';

const PrivacyPolicyPage = () => (
  <Layout>
    <section className="content static-page after-nav">
      <div className="lab3-container">
        <div className="columns">
          <div className="column is-12">
            <h1 id="privacy-policy">Privacy Policy</h1>
            <p>
              <em>Date Updated: 28/06/2019.</em>
            </p>
            <p>
              We collect personal information from you, including information
              about your:
            </p>
            <ul>
              <li>Name</li>
              <li>Contact information</li>
              <li>Location</li>
              <li>Computer or network</li>
              <li>Interactions with us</li>
              <li>Billing or purchase information</li>
            </ul>
            <p>We collect your personal information in order to:</p>
            <ul>
              <li>Provide services and products to you</li>
              <li>
                Personalise your experience and provide content and
                functionality that is specific to you.
              </li>
              <li>
                Bill you and to collect money that you owe us, including
                authorising and processing credit card transactions.
              </li>
            </ul>
            <p>Besides our staff, we share this information with:</p>
            <ul>
              <li>
                Our clients in order to allow them to provide personal and
                catered experiences.
              </li>
              <li>
                Any business that supports our services and products, including
                any person that hosts or maintains any underlying IT system or
                data centre that we use to provide the website or other services
                and products.
              </li>
            </ul>
            <p>
              Providing some information is optional. If you choose not to enter
              personal information, we’re unable to provide personal experiences
              and functionality requiring authentication.
            </p>
            <p>
              We keep your information safe by storing it in encrypted files and
              only allowing certain staff to access it.
            </p>
            <p>
              If you follow a link on our website to another site, the owner of
              that site will have its own privacy policy relating to your
              personal information. We suggest you review that site’s privacy
              policy before you provide personal information.
            </p>
            <h2 id="cookies">Cookies</h2>
            <p>
              We use cookies (an alphanumeric identifier that we transfer to
              your computer’s hard drive so that we can recognise your browser)
              to monitor your use of the website. You may disable cookies by
              changing the settings on your browser, although this may mean that
              you cannot use all of the features of the website.
            </p>
            <h2 id="accessing-and-correcting-your-personal-information">
              Accessing and Correcting Your Personal Information
            </h2>
            <p>
              You have the right to ask for a copy of any personal information
              we hold about you, and to ask for it to be corrected if you think
              it is wrong. If you’d like to ask for a copy of your information,
              or to have it corrected, please contact us at{' '}
              <a href="mailto:admin@lab3.co.nz">admin@lab3.co.nz</a>, or{' '}
              <a href="tel:+64277140246">+64277140246</a>, or Level 2, 122
              Victoria Street, Christchurch, New Zealand.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrivacyPolicyPage;
